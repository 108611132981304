import { onUnmounted } from 'vue';

const targetStore: Record<string, any>  = {};
const targetCount = new Map<string, number>();

export function addToStore(name: string, value: any) {
    let count = 0;
    if (targetStore[name]) {
        targetStore[name] = value;
        if (targetCount.has(name)) {
            count += targetCount.get(name)!;
        }
        targetCount.set(name, count + 1);
    } else {
        targetStore[name] = value;
        targetCount.set(name, count + 1);
    }
}

export function getByName(name: string) {
    return targetStore[name];
}

export default function useTarget(options: any) {
    const name = options.name;
    const event = options.event ?? 'click';
    
    addToStore(name, {
        event: event,
        handler: options.handler
    });

    onUnmounted(() => {
        if (targetCount.has(name)) {
            const count = targetCount.get(name)!;
            if (count > 1) {
                targetCount.set(name, count -1)
            } else {
                targetCount.delete(name);
                delete targetStore[name];
            }
        } else {
            delete targetStore[name];
        }
    });
}
